.MuiAppBar-root {
    background: white !important;
    height: 68px !important;
    justify-content: center !important;


}
.contact-btn{
    border: none!important;
    background: transparent!important;
}
.contact-btn:focus,.MuiButtonBase-root.MuiButton-root.MuiButton-text:focus{
    outline: none!important;
}
.MuiAppBar-root a,.contact-btn, .MuiButtonBase-root a{
    position: relative;
    text-align: center;
    color: #222222 !important;
    /* padding-left: 8px;
    padding-right: 20px; */
    padding-left: 11px;
    padding-right: 11px;
    font-size: 1.08rem !important;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    text-decoration: none!important;   text-transform: uppercase!important;
}
.MuiAppBar-root a:hover, .contact-btn:hover,.MuiAppBar-root li:hover{
    background: white!important
}
.MuiAppBar-root img {
    /* height: 3.2em;
    width: 11em; */
    height: 2.2em;
    width: 6em;
}

.custom-nav div{
    display: flex !important;
    justify-content: space-between !important;
}



.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    font-size: 1rem !important;
    ;
    line-height: 26px !important;
    ;
    color: #444444 !important;
    ;
    margin-bottom: 0 !important;
    ;
}

/* 
.custom-nav{
    display: flex;
    justify-content: space-between;
}
.logo{
    cursor: auto!important;

}
.logo:hover{
    box-shadow: none!important;
}
.MuiAppBar-root img{
       height: 4.2em;
    width: 11em;

}
.loginBtn{
    width: 139px!important;
height: 44px!important;
padding: 12px 16px 12px 16px!important;
border-radius: 8px!important;
box-shadow: 0px 8px 20px 0px #4E60FF29!important;
background: #090B19!important;
color: white!important;
font-family: 'Nunito'!important;
font-size: 14px!important;
font-weight: 700!important;
line-height: 20px!important;
letter-spacing: 0.30000001192092896px!important;
text-align: center!important;
text-transform: capitalize!important;
margin: 30px!important;
}
      
@media only screen and (max-width: 900px) {
    .MuiAppBar-root{
        position: fixed!important;
    }
  } */
   /* Styles for screens smaller than 900px */
@media (max-width: 900px) {
    .custom-nav .MuiBox-root{
        display: none!important;
    }
   
}
.active{
    color:orangered!important
}
  