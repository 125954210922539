.header-container{
    background-image: url("../assets/background/page-title-bg.jpg");
  background-repeat: no-repeat, repeat;
}
.MuiAccordionSummary-root {
    background: rgb(198, 33, 33)!important;
    height: 60px!important;;
    
}
.MuiAccordionSummary-root p{
    text-transform:capitalize;
    color:white!important;
    /* font-size: 20px!important;; */
    user-select: none!important;
    /* padding: .375rem .75rem!important; */
    font-size: 1rem!important;
    line-height: 1.5!important;
    border-radius: .25rem!important;
    font-weight: 400!important;
    font-family: "Montserrat", sans-serif!important;
}
h1{
    color: white;
}
/* .MuiAccordionSummary-root p:hover{
    color: black!important;
} */
.MuiCollapse-root{
    background-color: rgb(171, 165, 165)!important;
}
.bg-about {
    background: url('../assets/background/pattern-background.jpg') no-repeat;
    background-size: cover;
}
.description-one{
    word-wrap: break-word!important;
    text-align: left!important;
    font-size: 2rem!important;
    font-family: "Montserrat", sans-serif!important;
}
.breadcrumb {
    display: flex;
}
@media (max-width: 900px) {
    .MuiAccordionSummary-root p{
        font-size: 0.9rem!important;
    }
    .breadcrumb {
    justify-content: center;
    }
   
}
/* .client-container{
    height:500px;
    display: flex;
    flex-wrap: wrap;
}
.client-container img{
    width: 200px;
    height: 200px;

} */
.MuiAccordionDetails-root{
padding: 0px !important;
}